<template>
  <div class="py-2">
    <BPageLoader v-if="isLoading && !data?.metaData?.offset" />
    <template v-else-if="fundraiser.turnOnComments">
      <div v-if="data?.comments?.length">
        <div
          class="flex justify-between font-semibold text-lg py-3 text-text-alternate-1"
        >
          <h2>Comments</h2>
          <p>
            (<span class="underline">{{ data.metaData.count }}</span
            >)
          </p>
        </div>
        <div
          class="pt-4 pb-2 relative flex border-t border-border-primary gap-4"
          v-for="{ id, createdAt, userdetail, comment } in data.comments"
          :key="id"
        >
          <span
            class="rounded-full font-bold bg-bg-alternate-3 text-xs xs:text-sm h-10 w-10 flex flex-shrink-0 justify-center items-center"
            :style="{
              backgroundColor: randomColor({
                luminosity: 'light',
                seed: `${userdetail.firstname[0]}${userdetail.lastname[0]}`,
                format: 'rgba',
                alpha: 0.3,
              }),
              color: randomColor({
                luminosity: 'bright',
                seed: `${userdetail.firstname[0]}${userdetail.lastname[0]}`,
              }),
            }"
          >
            {{ userdetail.firstname[0] }}{{ userdetail.lastname[0] }}</span
          >
          <div>
            <h2 class="text-sm font-semibold my-1">
              {{ userdetail.firstname }} {{ userdetail.lastname }}
            </h2>
            <p class="text-xs leading-5">{{ comment }}</p>
            <span
              class="text-text-alternate-3 text-xs inline-block my-2 font-medium"
            >
              {{ dayjs().to(createdAt) }}
            </span>
          </div>
        </div>
        <div v-if="isLoading" class="flex w-full justify-center py-8">
          <BButtonLoader class="h-6 w-6" />
        </div>
        <button
          v-else-if="!data.metaData.isLastPage"
          class="border border-btn-border-secondary items-center w-full px-4 py-3.5 xs:py-4 text-center rounded text-text-alternate-1 font-semibold text-sm mt-4 mb-6"
          @click="handleShowMore"
        >
          Show more
        </button>
      </div>
      <div
        class="flex flex-col items-center text-center px-6 pt-6 pb-10 text-text-primary"
        v-else
      >
        <CommentIcon class="my-8" />
        <h3 class="py-2 font-semibold text-lg">No Comments</h3>
        <p class="px-5 py-2 text-xs">This fundraiser has no comments yet.</p>
      </div>
      <form
        class="border-t border-border-primary pt-4"
        @submit.prevent="handleSubmit"
        v-if="!isExpired && authUser && !(isLoading && !data?.metaData?.offset)"
      >
        <p class="text-xs font-semibold">
          <span class="text-text-alternate-3">Logged in as: </span>
          <span class="text-text-alternate-1">
            {{ authUser.firstname }} {{ authUser.lastname }}
          </span>
        </p>
        <div
          class="border rounded border-border-primary flex w-full items-center overflow-hidden my-4 input-shadow"
        >
          <input
            class="p-4 focus:outline-none flex-grow text-xs"
            type="text"
            placeholder="Comment here..."
            v-model="comment"
            :readonly="commentResponse.isLoading"
          />
          <BButtonLoader class="mx-3 h-5 w-5" v-if="commentResponse.isLoading" />
          <button class="focus:outline-none" type="submit" v-else>
            <SendIcon class="h-6 fill-current mx-3" />
          </button>
        </div>
      </form>
    </template>
    <div
      class="flex flex-col items-center text-center px-6 py-4 text-text-primary"
      v-else
    >
      <CommentIcon class="my-4" />
      <h3 class="py-2 font-semibold text-lg">Comment is turned off</h3>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, watch } from 'vue';
import randomColor from 'randomcolor';
import { useApi } from '@/cmp-functions/useApi';
import { getFundraiserComments, postFundraiserComment } from '@/services/api';
import BPageLoader from '@/components/ui/BPageLoader';
import BButtonLoader from '@/components/ui/BButtonLoader';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const handlePaginationResponse = (pre, { data }) => {
  if (!data?.data) return pre;
  const {
    data: { comments, count },
    metadata,
  } = data;

  return {
    comments: [...pre.comments, ...comments],
    metaData: {
      offset: comments.length + pre.comments.length,
      count,
      ...metadata,
    },
  };
};

export default {
  name: 'Comments',

  props: ['fundraiser', 'authUser', 'isExpired'],

  components: { BPageLoader, BButtonLoader },

  setup(props) {
    const [response, getComments] = useApi(
      getFundraiserComments,
      {
        comments: [],
        metaData: { offset: 0 },
      },
      handlePaginationResponse
    );

    if (props.fundraiser.turnOnComments) {
      getComments({
        fundraiserId: props.fundraiser.id,
        offset: response.data.metaData.offset,
      });
    }

    const comment = ref('');

    const [commentResponse, postComment] = useApi(postFundraiserComment);

    const handleSubmit = () => {
      if (!comment.value.trim()) return;
      postComment({
        comment: comment.value.trim(),
        fundraiserId: props.fundraiser.id,
        userId: props.authUser.id,
      });
    };

    watch(commentResponse, ({ isSuccess }) => {
      if (isSuccess) {
        const { firstname, lastname, id: userid } = props.authUser;
        const userdetail = { firstname, lastname, userid };
        const createdAt = Date.now();
        response.data.comments.unshift({
          userdetail,
          id: createdAt,
          createdAt,
          comment: comment.value.trim(),
        });
        response.data.metaData.count += 1;
        response.data.metaData.offset += 1;
        comment.value = '';
      }
    });

    const handleShowMore = () => {
      const offset = response.data.metaData.offset;
      getComments({ fundraiserId: props.fundraiser.id, offset });
    };

    return {
      randomColor,
      handleSubmit,
      comment,
      dayjs,
      handleShowMore,
      ...toRefs(response),
      commentResponse,
    };
  },
};
</script>
